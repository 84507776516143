@charset "UTF-8";
.supersmall {
  font-size: 10px;
}

@font-face {
  font-family: "Iosevka";
  src: url("/fonts/iosevka-regular.ttf") format("truetype");
}
@font-face {
  font-family: "Iosevka-light";
  src: url("/fonts/iosevka-extralight.ttf") format("truetype");
}
@font-face {
  font-family: "Iosevka-heavy";
  src: url("/fonts/iosevka-heavy.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  src: url("/fonts/gilroy-regular-webfont.woff") format("woff");
}
@font-face {
  font-family: "Gilroy";
  src: url("/fonts/gilroy-bold-webfont.woff") format("woff");
  font-weight: 700;
}
html {
  font-size: 16px;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

body {
  overflow-x: hidden;
  position: relative;
  min-height: calc(100vh - 32px);
  padding-bottom: 10rem;
  text-size-adjust: 100%;
  color: #3a3a3a;
  margin: 0;
  background-color: #ececec;
  transition: background 200ms;
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}

.js_color-button {
  font-family: "Proxima-Nova", sans-serif;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: "Proxima-Nova";
  font-weight: bold;
  bottom: 30px;
  left: 30px;
  min-width: 79px;
  height: 30px;
  border: 3px solid #3a3a3a;
  user-select: none;
  transition: height 220ms, width 220ms, top 220ms, left 220ms, transform 220ms, letter-spacing 220ms;
  z-index: 1;
  border: 3px solid transparent;
  border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
  border-image-slice: 1;
  margin: 20px auto;
  display: none;
}
.js_color-button:hover {
  letter-spacing: 3px;
  height: 38px;
  width: 58px;
  bottom: 26px;
  left: 26px;
  transform: rotateZ(-10deg);
}
@media (min-width: 992px) {
  .js_color-button {
    display: flex;
  }
}

/* General Typography
 * ----------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 600;
  margin: 0;
  margin-bottom: 8px;
}

h1 {
  font-weight: bold;
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 35px;
  font-size: rem-calc(35);
}
@media (min-width: 992px) {
  h1 {
    font-size: 40px;
    font-size: rem-calc(40);
  }
}

h2 {
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 28px;
  font-size: rem-calc(28);
}
@media (min-width: 992px) {
  h2 {
    font-size: 30px;
    font-size: rem-calc(30);
  }
}

h3 {
  font-size: 25px;
  font-size: rem-calc(25);
}

h4,
h5,
h6 {
  font-size: 22px;
  font-size: rem-calc(22);
}

p {
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 20px;
  font-size: rem-calc(20);
  line-height: 28px;
}

li {
  font-size: 20px;
  font-size: rem-calc(20);
  line-height: 30px;
}

ul,
ol,
dl {
  margin-top: 0;
  padding: 0;
}

a {
  color: #54004f;
  transition: color 0.2s ease 0s;
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
a:hover {
  cursor: pointer;
  color: #21001f;
}
a:focus {
  color: #21001f;
  outline: none;
}
a.link-accent {
  color: #54004f;
}
a.link-accent:hover {
  color: #21001f;
}

/*
    Paraíso (dark)
    Created by Jan T. Sott (http://github.com/idleberg)
    Inspired by the art of Rubens LP (http://www.rubenslp.com.br)
*/
/* Paraíso Comment */
.hljs-comment,
.hljs-quote {
  color: #948e90;
}

/* Paraíso Red */
.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-link,
.hljs-meta {
  color: #ef6155;
}

/* Paraíso Orange */
.hljs-number,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-deletion {
  color: #f99b15;
}

/* Paraíso Yellow */
.hljs-title,
.hljs-section,
.hljs-attribute {
  color: #fec418;
}

/* Paraíso Green */
.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
  color: #e8ffea;
}

/* Paraíso Purple */
.hljs-keyword,
.hljs-selector-tag {
  color: #815ba4;
}

.hljs {
  display: block;
  overflow-x: auto;
  background: #b4feff;
  color: #a39e9b;
  padding: 0.5em;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

/* An atom describes a low-level unit
/* Usually, it refers to a single HTML element, but not always
/**************************************************************/
blockquote p {
  display: inline-block;
  border-left: 5px solid #54004f;
  font-style: italic;
  padding: 0.5rem;
  padding-left: 1rem;
  background: #f5f5f5;
}

.btn {
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  display: inline-block;
  padding: 6px 18px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  text-decoration: none;
  text-align: center;
  border: 3px solid transparent;
  transition: background-color 0.2s ease 0s;
  font-size: 16px;
  font-size: rem-calc(16);
}
.btn--primary {
  background-color: white;
  color: #54004f;
}
.btn--primary:visited {
  background-color: white;
  color: #54004f;
}
.btn--primary:hover {
  background-color: transparent;
  border: 3px solid #fff;
  color: #fff;
}
.btn--accent {
  border: 1px solid transparent;
  background-color: #3a3a3a;
  color: white !important;
}
.btn--accent:hover {
  border: 1px solid #3a3a3a;
  background-color: transparent;
  color: #3a3a3a !important;
}

code {
  border-radius: 4px;
  border: 0;
  background: #ddd;
  padding: 3px;
  font-size: 16px;
  font-size: rem-calc(16);
  font-family: "Iosevka", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
code span {
  white-space: pre-wrap;
}

img {
  max-width: 100%;
}

ul ul li {
  margin-left: 2rem;
}

li {
  margin-left: 1rem;
}

pre {
  background-color: #b4feff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
}

pre code {
  border: 0;
  background: none;
  color: #fff;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1rem;
}

th,
td {
  vertical-align: middle;
  word-wrap: break-word;
  padding: 8px;
}

td {
  border: 1px solid #ddd;
}

th {
  font-weight: normal;
  background: #54004f;
  color: #fff;
}

/* Components (aka molecules)
/* They refer to groups of Atoms
/* They have some sort of specific functionality
/************************************************/
/* Modules (aka organisms)
/* They refer to groups of Components
/* They are "larger", and usually take the form of page sections
/*****************************************************************/
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3a3a3a;
  width: 100%;
  max-width: 100%;
  position: absolute;
  bottom: 0;
  min-height: 130px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.footer a {
  color: #fff;
  font-size: 22px;
  margin-left: 10px;
  margin-right: 10px;
}

.highlight-bar {
  opacity: 0;
  align-items: center;
  color: white;
  font-weight: bold;
  background: linear-gradient(to bottom right, #d63bff, #6b0cd8, #6687ff);
  background-size: 300% 300%;
  animation: gradient 0.5s alternate infinite;
  position: absolute;
  top: 0;
  height: 25px;
  width: 100%;
}

.header {
  margin-bottom: 1rem;
}
.header a,
.header p,
.header ul li {
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
.header.-no-margin-bottom {
  margin-bottom: 0;
}

.hero__container {
  width: 100%;
  padding-top: 2rem;
}
.hero__inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  .hero__inner {
    width: 90%;
    width: 50%;
  }
}
.hero__left, .hero__right {
  width: 50%;
}
.hero__left {
  opacity: 0;
  text-align: center;
  margin-bottom: 1.5rem;
  width: 100%;
}
@media (min-width: 992px) {
  .hero__left {
    margin-bottom: 0;
    text-align: left;
    width: 33%;
  }
}
.hero__right {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  text-align: center;
  width: 100%;
}
.hero__img {
  width: 200px;
  border-radius: 100%;
}

.upper-text__wrapper {
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 250px;
  max-width: 80vw;
  height: 120px;
  margin: auto;
  margin-bottom: 1.5rem;
  color: #3a3a3a;
  transition: transform 300ms, background-color 200ms;
}
.upper-text__wrapper:hover {
  transform: skew(10deg, 0deg);
}
@media (min-width: 767px) {
  .upper-text__wrapper {
    width: 300px;
    height: 144px;
    margin-bottom: 2.5rem;
  }
}
.upper-text__wrapper svg {
  position: absolute;
  z-index: -1;
}
.upper-text__wrapper svg,
.upper-text__wrapper svg svg rect {
  width: 250px;
}
@media (min-width: 767px) {
  .upper-text__wrapper svg,
.upper-text__wrapper svg svg rect {
    width: 300px;
  }
}
.upper-text__header, .upper-text__subheader {
  opacity: 0;
}
.upper-text__header {
  font-size: 2.25rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
.upper-text__subheader {
  font-size: 1rem;
}
.upper-text__subheader a {
  color: #3a3a3a;
  font-family: "Figtree", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
.upper-text__subheader a:hover {
  font-style: italic;
}

.lower-text__wrapper {
  opacity: 0;
}
.lower-text__text {
  line-height: 0.5rem;
  color: #3a3a3a;
  text-transform: uppercase;
  font-size: 15px;
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
@media (min-width: 475px) {
  .lower-text__text {
    font-size: 18px;
  }
}
.lower-text__text a {
  font-weight: bold;
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}
.navbar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-height: 55px;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100%;
  flex-basis: 100%;
}
.navbar.-full-width {
  background: #3a3a3a;
  max-width: 100%;
  flex-basis: 100%;
}
.navbar.-full-width a {
  color: #fff;
}
@media (min-width: 992px) {
  .navbar {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 75%;
    flex-basis: 75%;
  }
}
@media (min-width: 1080px) {
  .navbar {
    max-width: 66.6666666667%;
    flex-basis: 66.6666666667%;
  }
}
.navbar__name {
  margin-bottom: 0;
  display: none;
  font-size: 30px;
}
@media (min-width: 992px) {
  .navbar__name {
    display: inline;
  }
}
.navbar__list {
  width: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
@media (min-width: 992px) {
  .navbar__list {
    justify-content: flex-end;
  }
  .navbar__list.-is-home {
    justify-content: space-around;
  }
  .navbar__list.-is-home .navbar__list-link {
    opacity: 0;
  }
}
.navbar__list-item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 16px;
  min-width: 90px;
  cursor: pointer;
}
@media (min-width: 992px) {
  .navbar__list-item {
    font-size: 22px;
    padding: 0.5rem;
  }
}
@media (min-width: 1080px) {
  .navbar__list-item {
    padding: 1rem;
  }
}
.navbar__list-link {
  text-decoration: none;
  color: #54004f;
  letter-spacing: 1px;
  transition: letter-spacing 300ms;
  cursor: pointer;
}
.navbar__list-link:hover {
  font-weight: bold;
  letter-spacing: 1.9px;
}

.project-box__list {
  position: relative;
  margin: 0;
  padding: 0;
  flex-flow: row wrap;
}
.project-box__list.-projects {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem;
}
.project-box__list-item {
  display: inline-block;
  text-align: center;
}
.project-box__list-item a {
  display: inline-block;
  min-width: 107px;
  height: 100%;
  transition: background-color 100ms ease;
  border-radius: 3px;
}
.project-box__list-item a:hover {
  background-color: #54004f;
}
.project-box__list-post {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 10px;
  border: 1px solid transparent;
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
.project-box__list-post p {
  margin: 0;
}
.project-box__list-post .inner-link {
  width: 100%;
  padding: 1rem;
}
.project-box__list-post .post-excerpt {
  color: #2b2b2b;
}
.project-box__list-post .post-heading {
  display: block;
  width: 100%;
  color: #2b2b2b;
  text-decoration: none;
  transition: padding 0.2s ease;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.project-box__list-post:first-child:after {
  display: none;
}
@media (min-width: 992px) {
  .project-box__list-post:first-child:after {
    display: block;
    content: "LATEST";
    font-style: italic;
    position: absolute;
    font-size: 14px;
    top: 20px;
    right: -60px;
    color: #8a8a8a;
    pointer-events: none;
  }
}
.project-box__list-post:hover {
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #54004f;
}
.project-box__list-post:hover .post-heading {
  padding-left: 10px;
  cursor: pointer;
  font-style: italic;
}
.project-box__list-image {
  height: 75px;
  margin: 1rem;
}
.page-project-single .project-box__list-image {
  margin: auto;
  margin-bottom: 1rem;
}

.section__container:not(:first-child) {
  margin: auto;
  width: 95%;
  margin-bottom: 3rem;
}
.section__container--blog {
  min-height: 440px;
  margin: 1rem;
}
.section__container.-invisible-on-load {
  opacity: 0;
}
.section__inner {
  color: #3a3a3a;
  position: relative;
  border-top: 0px solid #3a3a3a;
  border-left: 0px solid #3a3a3a;
  border-right: 0px solid #3a3a3a;
  border-bottom: 0px solid #3a3a3a;
}
@media (min-width: 992px) {
  .section__inner.-regular {
    max-width: 66.6666666667%;
    flex-basis: 66.6666666667%;
  }
}
@media (min-width: 992px) {
  .section__inner.-thin {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 1080px) {
  .section__inner.-thin {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 992px) {
  .section__inner.-wide {
    max-width: 91.6666666667%;
    flex-basis: 91.6666666667%;
  }
}
@media (min-width: 1080px) {
  .section__inner.-wide {
    max-width: 66.6666666667%;
    flex-basis: 66.6666666667%;
  }
}
@media (min-width: 992px) {
  .section__inner {
    margin: auto;
    max-width: 91.6666666667%;
    flex-basis: 91.6666666667%;
  }
}
@media (min-width: 1080px) {
  .section__inner {
    margin: auto;
    max-width: 83.3333333333%;
    flex-basis: 83.3333333333%;
  }
}
.section__label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-top: 0px solid #3a3a3a;
  border-right: 0px solid #3a3a3a;
  border-left: 0px solid #3a3a3a;
  height: 29px;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1.2rem;
  text-align: center;
  position: absolute;
  top: -50px -1px;
}
.section__label-text {
  line-height: 24px;
  font-size: 24px;
  color: #3a3a3a;
  font-weight: 900;
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
.section__label.-project-box {
  width: 200px;
  right: 30px;
}
.section__label.-blog {
  width: 100px;
  right: 30px;
}
@media (min-width: 992px) {
  .section__label.-post {
    right: 30px;
  }
}
.section__projects h2 {
  font-size: 30px;
}
.section__projects, .section__blog, .section__about {
  width: 100%;
}
.section__content {
  padding: 1rem;
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
.section__content h1,
.section__content h2,
.section__content h3 {
  font-weight: bold;
}
.section__content h4 {
  font-size: 22px;
}
.section__content hr {
  margin-bottom: 2rem;
}
.section__content p,
.section__content li {
  font-size: 20px;
  line-height: 30px;
}
.section__content ul,
.section__content ol {
  padding-left: 1rem;
}
.section__content a {
  color: #54004f;
  font-weight: bold;
  text-decoration: underline;
}
.section__content a:hover {
  text-decoration: underline;
}
.section__content .about-intro {
  font-weight: bold;
}

/* Templates
/* Templates are generic Pages
/********************************/
/* Contains page-specific code
/* Pages are usually groups of modules
/**************************************/
.page--home {
  padding-top: 45px;
  max-width: 80%;
  margin: auto;
}
@media (min-width: 992px) {
  .page--home {
    padding-top: 75px;
  }
}
.page--home .home__header-wrapper {
  text-align: center;
  max-width: 730px;
  margin: auto;
}
.page--home .home__header {
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 40px;
  font-size: rem-calc(40);
}
@media (min-width: 992px) {
  .page--home .home__header {
    font-size: 50px;
    font-size: rem-calc(50);
  }
}
.page--home .home__subheader {
  text-align: center;
  margin-bottom: 40px;
  font-size: 20px;
  font-size: rem-calc(20);
}
@media (min-width: 992px) {
  .page--home .home__subheader {
    text-align: left;
    font-size: 25px;
    font-size: rem-calc(25);
  }
}
.page--home .home__cta-wrapper {
  text-align: center;
}
.page--home .home__cta-text {
  font-size: 18px;
  font-size: rem-calc(18);
  margin-bottom: 40px;
}
.page--home .app-api__container {
  width: 1080px;
  padding-bottom: 35px;
}
.page--home .app-api__item {
  padding: 1rem;
  text-align: center;
  max-width: 100%;
  flex-basis: 100%;
}
@media (min-width: 992px) {
  .page--home .app-api__item {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 1080px) {
  .page--home .app-api__item {
    max-width: 33.3333333333%;
    flex-basis: 33.3333333333%;
  }
}
.page--home .app-api__button {
  font-weight: bold;
  font-size: 20px;
  font-size: rem-calc(20);
}
@media (min-width: 1080px) {
  .page--home .app-api__button {
    min-width: 225px;
  }
}
.page--home .app-api__image {
  display: block;
  max-width: 160px;
  margin: auto;
  margin-bottom: 30px;
}
.page--home .app-api__description {
  font-size: 20px;
  font-size: rem-calc(20);
  line-height: 26px;
  text-align: left;
  max-width: 225px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}
.page--home .build-something__container {
  width: 100%;
  background-color: #fcfcfc;
  margin-bottom: 60px;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 1080px) {
  .page--home .build-something__container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.page--home .build-something__header {
  font-size: 25px;
  font-size: rem-calc(25);
  font-weight: bold;
  color: #54004f;
  text-align: center;
  margin-bottom: 30px;
}
.page--home .build-something__subheader {
  font-size: 20px;
  font-size: rem-calc(20);
  text-align: center;
  margin-bottom: 30px;
}
.page--home .build-something__container .api-description__wrapper {
  padding: 1rem;
  width: 100%;
}
.page--home .build-something__container .api-description__item {
  padding: 1rem;
  max-width: 100%;
  flex-basis: 100%;
}
@media (min-width: 1080px) {
  .page--home .build-something__container .api-description__item {
    max-width: 33.3333333333%;
    flex-basis: 33.3333333333%;
  }
}
@media (min-width: 1080px) {
  .page--home .build-something__container .api-description__item {
    position: relative;
    left: 20px;
  }
}
.page--home .build-something__container .api-description__heading, .page--home .build-something__container .api-description__description {
  font-size: 16px;
  font-size: rem-calc(16);
}
.page--home .build-something__container .api-description__heading {
  color: #54004f;
  font-weight: bold;
  margin-bottom: 15px;
}
.page--home .build-something__container .api-description__description {
  line-height: 22px;
  text-align: left;
}
@media (min-width: 1080px) {
  .page--home .build-something__container .api-description__description {
    max-width: 250px;
  }
}
.page--home .getting-started__container {
  width: 1080px;
  margin-bottom: 50px;
}
.page--home .getting-started__header {
  max-width: 100%;
  flex-basis: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 40px;
}
.page--home .getting-started__inner {
  background-color: #54004f;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
}
@media (min-width: 1080px) {
  .page--home .getting-started__inner {
    padding: 2rem;
  }
}
.page--home .getting-started__divider {
  width: 40%;
  border-bottom: 1px solid white;
}
.page--home .getting-started__item {
  padding: 0;
  margin-bottom: 1rem;
  max-width: 100%;
  flex-basis: 100%;
}
@media (min-width: 1080px) {
  .page--home .getting-started__item {
    max-width: 33.3333333333%;
    flex-basis: 33.3333333333%;
    margin-bottom: 0;
  }
}
.page--home .getting-started__item-header, .page--home .getting-started__item-text {
  color: white;
}
.page--home .getting-started__item-header {
  font-size: 20px;
  font-size: rem-calc(20);
  font-weight: bold;
  margin-bottom: 10px;
}
.page--home .getting-started__item-text {
  font-size: 16px;
  font-size: rem-calc(16);
  line-height: 22px;
}
.page--home .getting-started__list-item {
  font-size: 16px;
  font-size: rem-calc(16);
  color: white;
}
.page--home .getting-started__list-link {
  color: white;
  font-weight: bold;
}
.page--home .getting-started__list-link:hover {
  color: #cccccc;
}

.page-post-single {
  background-color: #fff;
}
.page-post-single .header {
  margin-bottom: 0;
}
.page-post-single .post-single__heading-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
  background: #3a3a3a;
  border-bottom: 5px solid #54004f;
}
@media (min-width: 992px) {
  .page-post-single .post-single__heading-wrapper {
    height: 150px;
  }
}
.page-post-single .post-single__heading {
  text-align: center;
  padding-right: 5px;
  padding-left: 5px;
  font-family: "Figtree", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
.page-post-single .post-single__subheading {
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  text-transform: lowercase;
  color: #7a7a7a;
}
.page-post-single .post-single__content-wrapper {
  max-width: 800px;
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 4rem;
  background-color: #fff;
  color: #3a3a3a;
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
.page-post-single .post-single__content-wrapper a {
  color: #54004f;
  margin-top: 2rem;
}

.page-project-single {
  margin-top: 30px;
}
.page-project-single .project-single__hero {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  padding: 2rem;
  border-bottom: 1px solid #fff;
  align-items: flex-start;
}
@media (min-width: 992px) {
  .page-project-single .project-single__hero {
    align-items: center;
  }
}
.page-project-single .project-single__heading {
  font-size: 30px;
  margin-bottom: 20px;
}
.page-project-single .project-single__subheading {
  font-style: italic;
  max-width: 75vw;
  text-align: center;
  font-size: 24px;
  font-size: rem-calc(24);
  font-family: "Figtree", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
.page-project-single .project-single__about-text {
  width: 95%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 2rem;
  color: #3a3a3a;
  background-color: #fafafa;
  border-radius: 4px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
}
@media (min-width: 992px) {
  .page-project-single .project-single__about-text {
    width: 90%;
  }
}
@media (min-width: 1080px) {
  .page-project-single .project-single__about-text {
    margin-top: 2rem;
    margin-bottom: 3rem;
    width: 60%;
  }
}
.page-project-single .project-single__about-text p:first-child {
  margin-top: 0;
}
.page-project-single .project-single__about-text p,
.page-project-single .project-single__about-text ul li,
.page-project-single .project-single__about-text a {
  font-size: 18px;
}
.page-project-single .project-single__about-text a:not(.btn) {
  color: #54004f;
  font-weight: bold;
}

.page-projects {
  padding-top: 60px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 767px) {
  .page-projects {
    width: 100%;
  }
}
.page-projects__heading-wrapper {
  max-width: 100%;
  flex-basis: 100%;
  height: 70px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}
.page-projects__heading-wrapper p {
  display: inline-block;
  width: 80vw;
  text-align: center;
  font-style: italic;
}
.page-projects__heading {
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}
.page-projects .project__container {
  margin: auto;
  margin-top: 1rem;
  width: 95%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
@media (min-width: 992px) {
  .page-projects .project__container {
    width: 85%;
  }
}
.page-projects .project__left, .page-projects .project__right {
  display: flex;
  flex-flow: column nowrap;
}
@media (min-width: 767px) {
  .page-projects .project__left, .page-projects .project__right {
    width: 50%;
  }
}
.page-projects .project__left {
  justify-content: center;
}
.page-projects .project__right {
  justify-content: space-between;
  margin-left: 1rem;
}
.page-projects .project__card {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 2rem;
  width: 99%;
  border-radius: 10px;
  filter: saturate(0.7);
  transition: filter 180ms;
  background-color: rgb(49, 49, 49);
  color: #fff;
}
@media (min-width: 767px) {
  .page-projects .project__card {
    width: 80%;
    min-height: 325px;
  }
}
@media (min-width: 1080px) {
  .page-projects .project__card {
    width: 48%;
  }
}
@media (min-width: 1920px) {
  .page-projects .project__card {
    width: 31%;
  }
}
.page-projects .project__card img {
  transform: none;
  transition: transform 0.4s;
}
.page-projects .project__card img.-circle-logo {
  border-radius: 100%;
}
.page-projects .project__card:hover {
  filter: saturate(1);
}
.page-projects .project__card:hover img {
  transform: translateY(-4px) scale(1.1);
}
.page-projects .project__card-inner {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  min-width: 220px;
  padding: 2rem;
  border-radius: 4px;
}
@media (min-width: 767px) {
  .page-projects .project__card-inner {
    flex-flow: row nowrap;
  }
}
.page-projects .project__title {
  margin-bottom: 0.8rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
.page-projects .project__description {
  max-width: 420px;
  font-size: 20px;
  font-size: rem-calc(20);
}
.page-projects .project__learn-more {
  text-align: center;
  display: inline-block;
}
@media (min-width: 992px) {
  .page-projects .project__learn-more {
    max-width: 200px;
  }
}
.page-projects .project__image {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  display: inline-block;
  text-align: center;
}
.page-projects .project__image img {
  width: 125px;
  max-height: 140px;
}

.page-scrawls {
  width: 100%;
}
.page-scrawls__heading-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  margin-bottom: 100px;
}
.page-scrawls__heading {
  font-family: "Figtree", "Proxima-Nova", "Helvetica", "Arial", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}